import _ from 'lodash';
import React from 'react';

import { Icon } from '@core/ui';

import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';

export interface IHubBranding {
  centered: boolean;
  whitelabel: boolean;
  namespace: {
    name: string;
  };
}

export const HubBranding = ({ whitelabel, namespace, centered }) => {
  if (whitelabel) {
    return null;
  }

  return (
    <a
      href={`https://stoplight.io/documentation?utm_source=${_.get(
        namespace,
        'name'
      )}&utm_medium=docs&utm_campaign=powered_by`}
      className="hover:text-primary transition-all"
      style={{
        width: '100%',
        display: 'flex',
        visibility: 'visible',
        alignItems: 'center',
        justifyContent: centered ? 'center' : 'flex-start',
        maxWidth: centered ? '100%' : '300px',
        height: '3rem',
        opacity: 0.75,
        color: 'rgba(19, 15, 33, .7)',
        background: 'rgba(19, 15, 33, .075)',
        padding: '0 13px',
      }}
    >
      <div className="ml-1 pr-3 text-primary">
        <Icon icon={faBolt} />
      </div>

      <div style={{ display: 'block', visibility: 'visible', color: 'rgba(19, 15, 33, .7)' }}>
        Powered by <b>Stoplight</b>
      </div>
    </a>
  );
};
