import _ from 'lodash';

/**
 * Parses an error object that may or may not be coming from the Stoplight API. Useful for display purposes.
 *
 * Returns an object with a message (string) property and an errors (array) property.
 */
export const parseApiError = ({ error } = {}) => {
  const targetError = _.get(error, 'response.data', error);

  let message = '';
  let errors = [];

  if (typeof targetError === 'object') {
    if (targetError.code) {
      message = `${targetError.name || targetError.code}: ${targetError.message}`;
    } else {
      message = targetError.reason || targetError.message || targetError.error || targetError;

      if (typeof message === 'object') {
        const keys = Object.keys(message);
        message = `${message[keys[0]]}`;
      }
    }

    if (targetError.errors) {
      // if errors is an array of strings, we can just use it
      if (_.isArray(targetError.errors) && typeof targetError.errors[0] === 'string') {
        errors = targetError.errors;
      } else {
        // else if its an array of objects, or an object itself, needs special handling
        for (const e in targetError.errors) {
          if (targetError.errors[e].name && targetError.errors[e].message) {
            errors.push(`${targetError.errors[e].name}: ${targetError.errors[e].message}`);
          } else {
            errors.push(`${_.capitalize(e)} ${targetError.errors[e]}`);
          }
        }
      }
    }
  } else if (typeof targetError === 'string') {
    message = targetError;
  }

  return {
    message,
    errors,
    toString() {
      return message;
    },
  };
};
