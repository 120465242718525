import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Input, Label } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';

class FormInput extends React.Component {
  render() {
    const {
      input = {},
      className = '',
      value,
      defaultValue,
      label,
      tip,
      required,
      prefix,
      suffix,
      inverted,
      transparent,
      size = '',
      readOnly,
      error,
      ...otherProps
    } = this.props;

    const valueProp = {};

    if (defaultValue || input.defaultValue) {
      valueProp.defaultValue = input.defaultValue || defaultValue;
    } else {
      valueProp.value = input.value || value || '';
    }

    if (prefix) {
      input.label = prefix;
      input.labelPosition = 'left';
    } else if (suffix) {
      input.label = suffix;
      input.labelPosition = 'right';
    }

    if (readOnly) {
      input.onChange = _.noop;
    }

    let isFixedWidth;
    let labelElem;

    if (_.isObject(label)) {
      isFixedWidth = label.className === 'Label-fw';

      labelElem = (
        <Label {...label} inverted={inverted} className={cn(label.className, 'flex-1')} />
      );
    } else {
      labelElem = (
        <FormInputLabel label={label} tip={tip} required={required} inverted={inverted} />
      );
    }

    return (
      <div
        className={cn('FormInput relative', className, size, {
          'flex items-center': isFixedWidth,
          transparent,
          'read-only': readOnly,
        })}
      >
        {labelElem}

        <div className={cn('flex-1', { 'border border-red': error })}>
          <Input {...otherProps} {...input} {...valueProp} readOnly={readOnly} />
        </div>

        {error ? <span className="text-sm text-red absolute pl-1">{error}</span> : null}
      </div>
    );
  }
}

export default FormInput;
