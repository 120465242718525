import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { Message } from 'semantic-ui-react';

import { parseApiError } from '@platform/utils/errors';

const ErrorMessage = ({
  error,
  className = '',
  style = {},
  onDismiss,
  messageProps = {},
  bordered,
  children,
}) => {
  if (!error) {
    return null;
  }

  const parsedError = parseApiError({ error });

  return (
    <div
      className={cn('ErrorMessage', className, {
        'has-messages': !_.isEmpty(parsedError.errors),
        'is-bordered': bordered ? true : false,
      })}
      style={style}
    >
      <Message
        size="mini"
        negative
        header={
          <div className="ErrorMessage-header">
            <span>{parsedError.message}</span>
          </div>
        }
        list={_.isEmpty(parsedError.errors) ? null : parsedError.errors}
        onDismiss={onDismiss}
        {...messageProps}
      />

      {children}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ErrorMessage;
